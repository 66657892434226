<template>
  <b-modal
    id="modal_add_derivado"
    :title="nombreModal"
    title-class="text-white"
    ok-only
    size="lg"
    :header-bg-variant="cabezeraModal"
    hide-footer
    varian
  >

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <div class="border rounded py-1">
        <h3 class="text-center text-primary">
          <strong>ESTUDIANTE:</strong> <span class=" ml-1 text-align-center text-primary">{{ estudianteSelect.nombres }}
            - <span class="text-danger">{{ estudianteSelect.numero_documento === '' ? 'sin documento' :estudianteSelect.numero_documento }}</span>
          </span>
        </h3>

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- SERVICIOS -->
        <b-row>
          <b-col
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="selectedServicio"
              rules="required"
            >
              <b-form-group
                label="Seleccione el servicio"
                label-for="selectedServicio"
              >
                <b-form-select
                  id="selectedServicio"
                  v-model="infraccion.servicio_id"
                  :options="servicioOptions"
                  disabled
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <!-- Descripción -->
            <validation-provider
              #default="validationContext"
              name="descripcion"
              rules="required"
            >
              <b-form-group
                label="Motivo (opcional)"
                label-for="descripcion"
              >
                <b-form-textarea
                  id="descripcion"
                  v-model="infraccion.motivo"
                  rows="3"
                  max-rows="6"
                  readonly
                  :state="getValidationState(validationContext)"
                  placeholder="Describa aquí.."
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <!-- Acción -->
            <validation-provider
              #default="validationContext"
              name="accion"
              rules="required"
            >
              <b-form-group
                label="Acciones (*)"
                label-for="accion"
              >
                <b-form-textarea
                  id="accion"
                  v-model="infraccion.accion"
                  rows="3"
                  max-rows="6"
                  :state="getValidationState(validationContext)"
                  placeholder="Describa aquí.."
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <!-- Form Actions -->
        <div class="d-flex justify-content-between ">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="$bvModal.hide('modal_add_derivado')"
          >
            <feather-icon
              icon="XIcon"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="cabezeraModal"
            type="submit"
          >
            <feather-icon
              :icon="vamosCrear ? 'SaveIcon' : 'RefreshCcwIcon'"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">{{ vamosCrear ? 'Guardar' : 'Actualizar' }}</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import {
BButton,
BCol,
BForm, BFormGroup, BFormInvalidFeedback,
BFormSelect,
BFormTextarea,
BModal,
BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormSelect,
    BButton,
    BCol,
    BRow,
    BModal,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    paciente: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      avatarText,
      required,
      vamosCrear: true,
      nombreModal: 'Formulario de interconsulta',
      nombreBotonModal: '',
      cabezeraModal: 'primary',
      infraccion: {},
      gravedadOptions: [],
      servicioOptions: [],
      naturalezaOptions: [],
      estudianteSelect: {},
    }
  },
  created() {
    store.dispatch('derivados/fetchServiciosOcade')
      .then(response => {
        this.servicioOptions = response.data.data.map(value => ({
          value: value.id, text: value.nombre,
        }))
      })
    this.$parent.$on('editarDerivado', this.editarDerivado)
    this.$parent.$on('nuevoDerivado', this.nuevoDerivado)
  },
  methods: {
    onSubmit() {
      if (this.estudianteSelect.usuario_id === undefined && this.vamosCrear) {
        this.toastAlert('Debe de seleccionar al paciente')
        return
      }
      if (this.vamosCrear) {
        this.createInfraccion()
      } else {
        this.updateInfraccion()
      }
    },
    createInfraccion() {
      this.infraccion.usuario_id = this.estudianteSelect.usuario_id
      store.dispatch('derivados/addDerivado', this.infraccion)
        .then(() => {
          this.toastSuccess()
          this.$emit('update-tabla-derivados')
          this.$bvModal.hide('modal_add_derivado')
        }).catch(() => {
          this.toastError()
        })
    },
    updateInfraccion() {
      const data = {
        id: this.estudianteSelect.id,
        data: this.infraccion,
      }
      store.dispatch('derivados/updateDerivado', data)
        .then(() => {
          this.toastSuccess()
          this.$emit('update-tabla-derivados')
          this.$bvModal.hide('modal_add_derivado')
        }).catch(() => {
          this.toastError()
        })
    },
    limpiarFormulario() {
      this.infraccion = {
      }
      this.estudianteSelect = {}
    },
    nuevoDerivado() {
      this.nombreModal = 'Nueva Infracción'
      this.nombreBotonModal = 'Grabar'
      this.cabezeraModal = 'primary'
      this.vamosCrear = true

      this.limpiarFormulario()
      this.$bvModal.show('modal_add_derivado')
    },

    editarDerivado(dataEditInfraccion) {
      this.nombreModal = 'Registrar recepción y acciones'
      this.nombreBotonModal = 'Actualizar'
      this.cabezeraModal = 'warning'
      this.vamosCrear = false
      this.limpiarFormulario()
      this.infraccion.usuario_id = dataEditInfraccion.usuario_id
      this.infraccion.servicio_id = dataEditInfraccion.servicio_id
      this.infraccion.motivo = dataEditInfraccion.motivo
      this.infraccion.accion = dataEditInfraccion.accion
      this.$set(this.estudianteSelect, 'nombres', `${dataEditInfraccion.persona.apellido_paterno} ${dataEditInfraccion.persona.apellido_materno} ${dataEditInfraccion.persona.nombres}`)
      this.$set(this.estudianteSelect, 'id', dataEditInfraccion.id)
      this.$set(this.estudianteSelect, 'numero_documento', dataEditInfraccion.persona.numero_documento)
      this.$bvModal.show('modal_add_derivado')
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>

<style lang="scss" scoped>
</style>
