import { render, staticRenderFns } from "./DerivadosFormModal.vue?vue&type=template&id=f673b994&scoped=true&"
import script from "./DerivadosFormModal.vue?vue&type=script&lang=js&"
export * from "./DerivadosFormModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f673b994",
  null
  
)

export default component.exports