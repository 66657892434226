import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDerivados(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/salud/derivados', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServiciosOcade() {
      return new Promise((resolve, reject) => {
        axios.get('/salud/derivados/tipos/servicios')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDerivado(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('/salud/derivados', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDerivado(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios.put(`/salud/derivados/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
