<template>
  <section>
    <b-card no-body>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <!-- Main table element -->
      <b-table
        ref="refTablaDerivados"
        hover
        show-empty
        responsive
        stacked="md"
        :items="myProvider"
        :no-provider-filtering="true"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        class="relative-position"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #cell(usuario)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="'light-primary'"
                :text="avatarText(row.item.persona.nombre_completo)"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ row.item.persona.nombre_completo }}
            </b-link>
            <small class="text-muted text-monospace py-0">DNI° {{ row.item.persona.numero_documento }} - CEL: {{ row.item.persona.celular ? row.item.persona.celular : '---------' }}</small>
          </b-media>
        </template>

        <template #cell(estado)="row">
          <b-badge :variant="`light-${setColorEstados(row.item.estado)}`">
            <span class="text-capitalize">{{ row.item.estado }}</span>
          </b-badge>
        </template>
        <template #cell(actions)="row">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template
              #button-content
            >
              <feather-icon
                icon="MoreHorizontalIcon"
                size="15"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal.modal_add_derivado
              @click="editar(row.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ row.item.estado === 'pendiente' ? 'Recepcionar' : 'Editar' }}</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item
              @click="info(row.item, row.index, $event.target)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Ver atenciones</span>
            </b-dropdown-item> -->

          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>

          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>

    </b-card>
    <!-- Info modal -->

    <DerivadosFormModal
      :paciente="paciente"
      @update-tabla-derivados="actualizarTabla"
    />

    <!-- <AtencionModalList :historia="historia" /> -->
  </section>
</template>

<script>
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import {
  BTable, BCard, BRow, BCol, BSpinner, BPagination, BFormInput,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  // BFormGroup, BFormSelect, BButton, BInputGroupAppend, BInputGroup,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useStoreDerivado from './useStoreDerivado'
import DerivadosFormModal from './DerivadosFormModal.vue'
// import AtencionModalList from './components/AtencionModalList.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BBadge,
    BTable,
    BMedia,
    vSelect,
    // BButton,
    BAvatar,
    BSpinner,
    BDropdown,
    // BFormGroup,
    BFormInput,
    // BFormSelect,
    BPagination,
    // BInputGroup,
    BDropdownItem,
    // AtencionModalList,
    DerivadosFormModal,
    // DerivadosFormnd,
  },
  data() {
    return {
      servicioId: 8,

      paciente: {},
      items: [],
      fields: [
        {
          key: 'usuario', label: 'Paciente',
        },
        {
          key: 'servicio.nombre', label: 'derivado a ',
        },
        {
          key: 'created_at', label: 'Fecha envio',
        },
        {
          key: 'fecha_atencion', label: 'Fecha Recepción',
        },
        {
          key: 'estado', label: 'estado',
        },
        { key: 'actions', label: 'Acciones' },
      ],
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      historia: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },

  methods: {
    async myProvider(ctx) {
      await this.$http.get('/servicio/OCADE-CULTURAL/buscar').then(res => {
        this.servicioId = res.data.id
      })
      // eslint-disable-next-line no-param-reassign
      ctx.servicio_id = this.servicioId
      const promise = store.dispatch('derivados/fetchDerivados', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response

        const items = data.map(item => {
          const { ...rest } = item
          const nuevoItem = { ...rest }
          nuevoItem.persona = item.usuario.perfil.persona
          return nuevoItem
        })

        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0

        this.to = to || 0
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    editar(item) {
      this.$emit('editarDerivado', item)
    },
    setColorEstados(val) {
      if (val === 'pendiente') return 'warning'
      if (val === 'atendido') return 'success'
      return 'primary'
    },
    actualizarTabla() {
      this.filter = null
      this.$refs.refTablaDerivados.refresh()
    },
    setHistoria(item) {
      this.paciente = item.persona
      this.paciente.historia_clinica_id = item.id
      this.paciente.historia_clinica = item.numero_historia_clinica
    },

    info(item, index, button) {
      this.historia = item
      this.$root.$emit('bv::show::modal', 'atenciones-modal', button)
    },
    resetInfoModal() {
      this.historia.title = ''
      this.historia.content = ''
    },
  },
  setup() {
    const DERIVADO_APP_STORE_MODULE_NAME = 'derivados'

    // Register module
    if (!store.hasModule(DERIVADO_APP_STORE_MODULE_NAME)) store.registerModule(DERIVADO_APP_STORE_MODULE_NAME, useStoreDerivado)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DERIVADO_APP_STORE_MODULE_NAME)) store.unregisterModule(DERIVADO_APP_STORE_MODULE_NAME)
    })
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
